import useGapi from './useGapi';
import { PublishContext, PublishMiniApplication } from "./types";

import PickerFolder from './picker';
import { DRIVE_MULTIPART_UPLOAD_URL } from './constants';

const importGoogleDriveId = 'import-google-drive';
const exportGoogleDriveId = 'export-google-drive';


const { init, authUser } = useGapi();
let base64ImageString: any;
let type: "content" | "edit" | "publish";

var buttons = [
	{
		type: 'import',
		id: 'driveImport',
		label: 'Import from Google Drive'
	},
	{
		type: 'export',
		id: 'driveExport',
		label: 'Export to Google Drive'
	}
];

class GoogleDrive implements PublishMiniApplication {
  type = 'content';
  async initGapi() {
    await init();
  }
  
  async authorizeGoogleUser() {
    if ((window as any).gapi?.auth2) {
      return;
    }

    await authUser(false);
  }
  async getGoogleUser(context) {
    await this.authorizeGoogleUser(context);
    console.log('User Authorized. Initializing Google Picker...');
    PickerFolder.init(context);
  };

  async saveToDrive(context) {
    // const imgSrc = document.getElementById('img')?.src;
    // console.log('Image URL', imgSrc);
    // document.getElementById('content').innerText = "Saving to Drive...";
    // const url = await this.getBase64ImageFromUrl(imgSrc).then(base64 => {
    //   base64ImageString = base64;
    //   console.log('urllll', base64);
    // })

    const { images } = context;
    const [{ data, mimeType }] = images;
    base64ImageString = `data:${mimeType};base64,${data}`;
    const fileName = 'Saved from NEW Web';
    
    // if (window.webkit) {
    //   window.webkit.messageHandlers.contextMessage.postMessage({ imageData: base64ImageString });
    // }

    fetch(base64ImageString).then((res: any) => res.blob()).then((blob : any) => {
      console.log('Preparing Form data to save to drive', blob);
      const form = new FormData();
      form.append('metadata', new Blob([JSON.stringify({ name: fileName })], { type: 'application/json' }));
      form.append('file', blob);
      console.log('Sending to Drive...', form, blob);
      fetch(DRIVE_MULTIPART_UPLOAD_URL, {
        method: 'POST',
        headers: new Headers({ Authorization: `Bearer ${(window as any).gapi.auth.getToken().access_token}` }),
        body: form,
      }).then((res: any) => res.json()).then((val: any) => console.log('Saved to Drive..!!!', val, form));
    });
    // document.getElementById('content').innerText = "";
  };

  async getBase64ImageFromUrl(imageUrl: string) {
    const res = await fetch(imageUrl);
    const blob = await res.blob();
    return new Promise((resolve, reject) => {
      const reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  async onGapiStart(context: PublishContext) {
		await init();
		console.log('application started', context);
		return context;
	}

  async onGapiexecute(context: PublishContext) {
		console.log('Execution started', context);
    if (context.userSettings?.showPicker) {
      await this.authorizeGoogleUser();
      this.saveToDrive(context);
    } else {
      this.getGoogleUser();
    }
		return context;
	}

  async initUI(context: PublishContext) {
    console.log('context', context);
    const {createElement} = context.ui;

    createElement('button', {
      id: importGoogleDriveId,
      children: 'Import from Google Drive',
    });
    createElement('button', {
      id: exportGoogleDriveId,
      children: 'Export to Google Drive',
    });

    // var buttonsWrapper = document.getElementById("drive-buttons");
		// var element = document.createElement("div");
		// var wrapHtmlAttr = document.createAttribute("class");
		// wrapHtmlAttr.value = "buttons-group";
		// element.setAttributeNode(wrapHtmlAttr);

		// for (let i = 0; i < buttons.length; i++) {
    //   let obj = buttons[i];

    //   // Creating Buttons and adding to UI
    //   let button = document.createElement('button');
    //   let textButton = document.createTextNode(obj.label);
    //   button.appendChild(textButton);
    //   button.setAttribute("id", obj.id);
    //   button.onclick = async function(event) {
    //     event.preventDefault();
    //     console.log('Event', obj);
    //     if (obj.type == 'import') {
    //       context = await window.application.execute(context);
    //     } else if (obj.type == 'export') {
    //       context = await window.application.execute({
    //         ...context,
    //         userSettings: {
    //           "showPicker": true,
    //         }
    //       });
    //     }
    //   }
    //   element.appendChild(button);

    //   let lineBreak = document.createElement("br");
    //   element.appendChild(lineBreak);

    //   // Append the buttons to the main DIV
    //   buttonsWrapper && buttonsWrapper.appendChild(element);
    // }
    return context;
  }

	async onStart(context: PublishContext) {
		await init();
		console.log('application started', context);
		return context;
	}

	async execute(context: PublishContext) {
		console.log('Execution started', context);
    if (context.ui.events) {
      if (context.ui.events.click === importGoogleDriveId) {
        this.getGoogleUser(context);
      }
      if (context.ui.events.click === exportGoogleDriveId) {
        await this.authorizeGoogleUser();
        this.saveToDrive(context);
        context.handlers.publish();
      }
    }
    // if (context.userSettings?.showPicker) {
    //   await this.authorizeGoogleUser();
    //   this.saveToDrive();
    // } else {
    //   this.getGoogleUser();
    // }
		return context;
	}

  async onFinish(context: PublishContext) {
		console.log('Finished application ', context);
		return context;
	}
};

window.application = new GoogleDrive();
window.application.type = 'content';
