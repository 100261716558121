import { PublishContext } from "./types";
import contextJSON from './context.json';
import './application'

let context: PublishContext = contextJSON;

async function run() {
	window.application.type = 'content';
  window.application.onStart && (context = await window.application.onStart(context));
	
	window.application.initUI && (context = await window.application.initUI(context));

  // context = await window.application.execute(context);
  // application.onFinish && (context = await application.onFinish(context));

  console.log('Run context', context);
}

run().catch(err => console.error('an error has occurred', err));
